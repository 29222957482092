import React, { useEffect, useContext } from "react";

import GlobalContext from "../../context/GlobalContext";
import SEO from "../Seo";

const PageWrapper = ({
  children,
  headerDark = false,
  footerDark = false,
  title = "Home",
  pathname,
  description,
  banner,
  article,
}) => {
  const gContext = useContext(GlobalContext);

  useEffect(() => {
    if (headerDark) {
      gContext.goHeaderDark();
    } else {
      gContext.goHeaderLight();
    }

    if (footerDark) {
      gContext.goFooterDark();
    } else {
      gContext.goFooterLight();
    }
  }, [gContext, headerDark, footerDark]);

  return (
    <>
      <SEO
        title={title}
        description={description}
        article={article}
        banner={banner}
        pathname={pathname}
      />
      {children}
    </>
  );
};

export default PageWrapper;
